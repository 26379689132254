<template>
    <page
        id="users-list"
        :title="$root.translate('User:::Users', {}, 2)"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <template v-slot:title>
            <h1>
                {{ $root.translate('User:::Users', {}, 2) }}
                <default-button
                    :title="$root.translate('New User')"
                    flavor="link"
                    @click.prevent="record_create(entity)"
                >
                    <open-icon glyph="plus" />
                </default-button>
            </h1>
        </template>
        <data-table
            id="users-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            :export-url="export_url"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @add="record_create(entity)"
            @click="record_edit(entity, $event.uuid)"
        >
            <template
                v-slot:controls
            >
                <div>
                    <div class="btn-group">
                        <default-button
                            :active="!state.filter_on || !state.filter"
                            @click.prevent="filter('role', null)"
                        >
                            {{ $root.translate('Any Role') }}
                        </default-button>
                        <default-button
                            v-for="role in roles"
                            :key="role.id"
                            :active="!!state.filter_on && (state.filter===role.id)"
                            @click.prevent="filter('role', role.id)"
                        >
                            {{ $root.translate(role.name) }}
                        </default-button>
                    </div>
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.name }}</h6>
                <p class="text-small">
                    <mailto-link
                        :value="row.email"
                    >
                        {{ row.email }}
                    </mailto-link>
                </p>
                <div v-if="!!row.invitation_expires_at" class="text-gray text-small">
                    <span v-if="invitation_expired(row)">{{ $root.translate("Invitation expired on") }}</span>
                    <span v-else>{{ $root.translate("Invitation valid until") }}</span>
                    {{ row.invitation_expires_at | nibnut.date("YYYY-MM-DD @ HH:mm") }}
                    <default-button
                        :title="$root.translate('Re-send invitation')"
                        flavor="link"
                        size="sm"
                        @click.prevent.stop="resend_invitation(row)"
                    >
                        <open-icon glyph="share" />
                    </default-button>
                </div>
                <div class="text-small text-gray">
                    {{ row.last_login_at | nibnut.date("MM/DD/YYYY") }}&nbsp;@&nbsp;{{ row.last_login_at | nibnut.date("HH:mm") }}
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'name'">
                    {{ row.name }}
                    <div v-if="!!row.invitation_expires_at" class="text-gray text-small">
                        <span v-if="invitation_expired(row)">{{ $root.translate("Invitation expired on") }}</span>
                        <span v-else>{{ $root.translate("Invitation valid until") }}</span>
                        {{ row.invitation_expires_at | nibnut.date("YYYY-MM-DD @ HH:mm") }}
                        <default-button
                            :title="$root.translate('Re-send invitation')"
                            flavor="link"
                            size="sm"
                            @click.prevent.stop="resend_invitation(row)"
                        >
                            <open-icon glyph="share" />
                        </default-button>
                    </div>
                </div>
                <span v-else-if="field === 'email'">
                    <mailto-link
                        :value="row.email"
                    >
                        {{ row.email }}
                    </mailto-link>
                </span>
                <span v-else-if="field === 'last_login_at'">
                    {{ row.last_login_at | nibnut.date("MM/DD/YYYY") }}&nbsp;@&nbsp;{{ row.last_login_at | nibnut.date("HH:mm") }}
                </span>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <reinvite-dialog
            :show.sync="reinviting"
            :user="reinvite"
        />

        <modal-dialog
            id="invite-user"
            :dismissable="!sending_invitation"
            :show.sync="inviting"
        >
            <template v-slot:title><span class="h5">{{ $root.translate("Invite User") }}</span></template>
            <base-form
                :has-required-fields="true"
                @submit.prevent="send_invitation"
            >
                <div class="columns">
                    <div class="column col-6 col-md-12 mb-2">
                        <form-input
                            id="email"
                            type="email"
                            name="email"
                            v-model="invitee.email"
                            :required="true"
                            :error="has_error('email')"
                        >
                            <template v-slot:label>{{ $root.translate("Email") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-6 col-md-12 mb-2">
                        <form-select
                            id="role"
                            name="role"
                            v-model="invitee.role"
                            :data-source="filtered_roles"
                            :required="true"
                            :error="has_error('role')"
                        >
                            <template v-slot:label>{{ $root.translate("Role") }}</template>
                        </form-select>
                    </div>
                </div>

                <div class="columns">
                    <div class="column col-6 col-lg-12 mb-2">
                        <form-input
                            id="first_name"
                            name="first_name"
                            v-model="invitee.first_name"
                            :required="false"
                            :error="has_error('first_name')"
                        >
                            <template v-slot:label>{{ $root.translate("First Name") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-6 col-lg-12 mb-2">
                        <form-input
                            id="last_name"
                            name="last_name"
                            v-model="invitee.last_name"
                            :required="false"
                            :error="has_error('last_name')"
                        >
                            <template v-slot:label>{{ $root.translate("Last Name") }}</template>
                        </form-input>
                    </div>
                </div>

                <div class="columns">
                    <div class="column col-12">
                        <invitation-expiries
                            id="expires_in"
                            name="expires_in"
                            v-model="invitee.expires_in"
                            :required="true"
                            :error="has_error('expires_in')"
                        />
                    </div>
                </div>
            </base-form>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        :waiting="sending_invitation"
                        :disabled="sending_invitation"
                        color="primary"
                        @click.prevent="send_invitation"
                    >
                        {{ $root.translate("Send Invitation") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
    </page>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import { is_page, is_remote_data_table_source, crud_utilities, is_user_inviter } from "@/nibnut/mixins"

import {
    DefaultButton,
    MailtoLink,
    ModalDialog,
    BaseForm,
    FormInput,
    FormSelect,
    InvitationExpiries,
    OpenIcon
} from "@/nibnut/components"

export default {
    mixins: [is_page, is_remote_data_table_source, crud_utilities, is_user_inviter],
    components: {
        DefaultButton,
        MailtoLink,
        ModalDialog,
        BaseForm,
        FormInput,
        FormSelect,
        InvitationExpiries,
        OpenIcon
    },
    methods: {
        record_create (entity) {
            this.record_shell(entity).then(record => {
                this.invitee = {
                    ...record,
                    expires_in: this.app_context.settings.default_invitation_delay
                }
                this.inviting = true
            })
        },
        invitation_expired (row) {
            if(!!row && !!row.invitation_expires_at) return this.$dayjs(row.invitation_expires_at).isBefore(this.$dayjs())
            return false
        },
        send_invitation () {
            this.sending_invitation = true
            return this.$store.dispatch("CREATE_RECORD", {
                entity: this.entity,
                data: this.invitee
            }).then(record => {
                this.inviting = false
                this.invitee = {}
                this.record_edit(this.entity, record.uuid)
            }).catch(this.receive_error).then(() => {
                this.sending_invitation = false
            })
        }
    },
    computed: {
        ...mapState(["app_context"]),
        fields () {
            return ["fieldset::list"]
        },
        roles () {
            return Object.values(this.constants("roles"))
        },
        filtered_roles () {
            return this.roles.filter(role => role.id !== this.constants("roles", "ROLE_DEVELOPER").id)
        }
    },
    data () {
        return {
            entity: "user",
            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" },
                email: { label: "Email", sort: null, type: "alpha" },
                last_login_at: { label: "Last Login", sort: null, type: "numeric" }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            inviting: false,
            invitee: {},
            sending_invitation: false
        }
    }
}
</script>

<style lang="scss">
#users-list {
    .btn-group {
        flex-wrap: nowrap;
    }
}
</style>
